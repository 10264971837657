import React from "react";
import Layout from "@components/layout";
import Header from "@components/header";
import SEO from "@components/seo";
import "@scss/404.scss";

const NotFoundPage = () => (
  <Layout>
    <Header isInterior={true} />
    <SEO title="404: Not found" />
    <div className="error">
      <div>
        <h1>NOT FOUND</h1>
        <p>
          You just requested a page that doesn&#39;t exist.
          <br />
          If you feel you're reaching this page in error contact us.
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
